// Copyright © 2019 3D Robotics. All rights reserved.

.logOutput {
  font-size: 85%;
  font-family: monospace;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 1.5em;
}

.logOutput > div {
  display: flex;
}

.logOutput > div:nth-child(odd) {
  background-color: #f5f5f5;
}

.logOutputItemTimestamp {
  margin-right: 1em;
  color: #888;
  font-size: 70%;
}

.selectionTime {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 0.5em;
  background: #ff8;
  font-variant-numeric: tabular-nums;
}
